import * as React from "react"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BsStars } from "react-icons/bs"
import { FaBrain } from "react-icons/fa"
import { GiFactory } from "react-icons/gi"
import { Piano } from "../components/Piano/Piano"

const MusicMakerPage = ({ data }) => {
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900 pt-1 pb-6  p-2">
      <Seo
        title={"✨Magic Music Maker"}
        description="Make music and explore sound 💭"
        defer={false}
        image="https://cdn.sanity.io/images/rbhjt84j/production/fdef6a81dd2297ba92e5e7989acc7cbba3bceeb2-1200x630.png"
        twitterImage="https://cdn.sanity.io/images/rbhjt84j/production/fdef6a81dd2297ba92e5e7989acc7cbba3bceeb2-1200x630.png"
      />
      {/* Blog Posts */}
      <section className="container mx-auto bg-grid-white mt-8 rounded text-white border">
        <div className="grid grid-cols-3 px-8 py-12 bg-[#7546ff]/80 rounded">
          <div className="flex mb-3 flex-col col-span-2 justify-center items-center">
            <p className="tracking-widest md:text-xs text-[.625rem] md:inline-flex hidden items-center gap-3 uppercase">
              Melody Exploration Tool <BsStars /> <FaBrain /> <GiFactory />
            </p>
            <h1 className="tracking-widest  md:text-8xl text-4xl font-bold">
              MAGIC
            </h1>
          </div>
          <div className="w-full flex items-center  justify-center md:mb-0 mb-3 bg-mm1 shadow-lg  rounded-full"></div>
          <div className="w-full flex items-center justify-center mb-0 bg-mm2 shadow-lg  rounded-full mr-4 md:mt-0 mt-3"></div>
          <h1 className="ml-4 tracking-widest flex justify-center col-span-2 items-center md:text-9xl text-4xl font-bold">
            MUSIC
          </h1>
          <h1 className="ml-4 tracking-widest flex justify-center col-span-2 items-center md:text-9xl text-4xl font-bold">
            MAKER
          </h1>
          <div className="w-full flex items-center justify-center mb-0 bg-mm3 shadow-lg  rounded-full mr-4 md:mt-0 mt-3"></div>

          {/* <p className="col-span-2 mb-0 mt-3 flex md:text-base text-xs items-center justify-center">
            Enter a mystical realm of idea-sharing and boundless creativity,
            where thoughts flow freely and the best ideas rise to the top. Use
            the Idea Engine to generate tons of ideas in no time, and quickly
            find the best ones to work on.
          </p>
          <div className="flex items-center justify-center">
            <img
              className="md:h-32 md:w-32"
              src="https://cdn.sanity.io/images/rbhjt84j/production/0ba0b47464ae262e9867a8e39e34516e5e412273-904x905.png"
            />
          </div> */}
        </div>
      </section>
      <section className="mt-8 container mx-auto lg:p-0 ">
        <Piano></Piano>
      </section>
    </div>
  )
}

export default MusicMakerPage
